<script>
import { required, email, minLength } from "vuelidate/lib/validators";
//register logic
export default {
  data() {
    return {
      user: {
        firstname: "",
        lastname: "",
        username: "",
        phone: "",
        address: "",
        email: "",
        password: "",
        inviteKey: "",
      },
      submitted: false,
      loading: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  validations: {
    user: {
      firstname: { required },
      lastname: { required },
      username: { required },
      phone: { required },
      address: { required },
      email: { required, email },
      password: { required, minLength: minLength(4) },
      inviteKey: { required }
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    register() {
      this.submitted = true;
      this.loading = true;
      // stop here if form is invalidd
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.loading = false
        return;
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/auth/register`;
        if (this.user.phone[0] === "0") {
          this.user.phone = this.user.phone.replace(
            this.user.phone[0],
            "233"
          );
        }
        this.$http
          .post(url, {
            firstName: this.user.firstname,
            lastName: this.user.lastname,
            phone: this.user.phone,
            email: this.user.email,
            username: this.user.username,
            address: this.user.address,
            password: this.user.password,
            inviteKey: this.user.inviteKey,
            authorizationType: "agent-authentication",
          })
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              this.$toast.success("Registration successfull");
              this.$router.push("/login");
            } else {
              this.$toast.error(
                "Registration failed, Talk to your Admininstrator"
              );
            }
          })
          .catch((err) => {
            this.loading = false
            this.$toast.error(err.response.data.message);
          });
      }
      this.loading = false
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4 m-auto">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="../../../assets/xefe.png"
                              height="100"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-2">Agent Registration</h4>
                        <p class="text-muted">
                          Enter your details to register.
                        </p>
                      </div>

                      <div class="p-2 mt-3">
                        <form
                          class="form-horizontal"
                          @submit.prevent="register()"
                        >
                          <div class="form-group auth-form-group-custom mb-2">
                            <i
                              class="ri-user-2-line auti-custom-input-icon"
                            ></i>
                            <label for="username">First Name</label>
                            <input
                              v-model="user.firstname"
                              type="text"
                              class="form-control"
                              id="username"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.firstname.$error,
                              }"
                              placeholder="Enter first name"
                            />
                            <div
                              v-if="submitted && !$v.user.firstname.required"
                              class="invalid-feedback"
                            >
                              First name is required.
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-2">
                            <i
                              class="ri-user-2-line auti-custom-input-icon"
                            ></i>
                            <label for="username">Last Name</label>
                            <input
                              v-model="user.lastname"
                              type="text"
                              class="form-control"
                              id="username"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.lastname.$error,
                              }"
                              placeholder="Enter last name"
                            />
                            <div
                              v-if="submitted && !$v.user.lastname.required"
                              class="invalid-feedback"
                            >
                              Last name is required.
                            </div>
                          </div>
                          <div class="form-group auth-form-group-custom mb-2">
                            <i
                              class="ri-user-2-line auti-custom-input-icon"
                            ></i>
                            <label for="username">Current PPMC Username</label>
                            <input
                              v-model="user.username"
                              type="text"
                              class="form-control"
                              id="username"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.username.$error,
                              }"
                              placeholder="Enter username"
                            />
                            <div
                              v-if="submitted && !$v.user.username.required"
                              class="invalid-feedback"
                            >
                              Username is required.
                            </div>
                          </div>
                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-phone-line auti-custom-input-icon"></i>
                            <label for="username">Phone Number</label>
                            <input
                              v-model="user.phone"
                              type="text"
                              class="form-control"
                              id="username"
                              :class="{
                                'is-invalid': submitted && $v.user.phone.$error,
                              }"
                              placeholder="Enter phone number"
                            />
                            <div
                              v-if="submitted && !$v.user.phone.required"
                              class="invalid-feedback"
                            >
                              Phone number is required.
                            </div>
                          </div>
                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-home-line auti-custom-input-icon"></i>
                            <label for="username">Address</label>
                            <input
                              v-model="user.address"
                              type="text"
                              class="form-control"
                              id="address"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.address.$error,
                              }"
                              placeholder="Enter home address"
                            />
                            <div
                              v-if="submitted && !$v.user.address.required"
                              class="invalid-feedback"
                            >
                              Address is required.
                            </div>
                          </div>
                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                              v-model="user.email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.user.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.user.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.user.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.user.email.email"
                                >Please enter valid email.</span
                              >
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-key-line auti-custom-input-icon"></i>
                            <label for="username">Invite Code</label>
                            <input
                              v-model="user.inviteKey"
                              type="text"
                              class="form-control"
                              id="inviteKey"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.inviteKey.$error,
                              }"
                              placeholder="Enter invite code recieved through SMS"
                            />
                            <div
                              v-if="submitted && !$v.user.inviteKey.required"
                              class="invalid-feedback"
                            >
                              Invite key is required.
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-2">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Current PPMC Password</label>
                            <input
                              v-model="user.password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.password.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.user.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                v-if="loading == true"
                              ></span>
                              Register
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-2 text-center">
                        <p>
                          Already have an account?
                          <router-link
                            tag="a"
                            to="/"
                            class="font-weight-medium text-primary"
                            >Login</router-link
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
